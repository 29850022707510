export const notifyListTableFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Child Name", objkey: "child_data", key: "name" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "User", objkey: "user_data_history", key: "name" },
  { text: "Comment", key: "comment" },
  {
    text: "Mark As Read",
    action: true,
    key: ["view"],
  },
  {
    text: "Action",
    action: true,
    key: ["edit"],
    actionFor: "child_registration",
  },
];
