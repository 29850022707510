export const rolesTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "User Roles", key: "name" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Action", action: true, key: ["setPermission", "edit", "delete"] },
];

export const roleDetailsFixture = {
  name: "",
};

export const rolePermissionsFixture = {
  id: "",
  child_tracking: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
  child_registration: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
  cwc: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
};

export const permissionFixture = [
  {
    label: "Child Tracking",
    key: "child_tracking",
    permissionFor: ["all", "create", "view", "edit", "delete"],
  },
  {
    label: "Child Registration",
    key: "child_registration",
    permissionFor: ["all", "create", "view", "edit", "delete"],
  },
  {
    label: "CWC",
    key: "cwc",
    permissionFor: ["all", "create", "view", "edit", "delete"],
  },
  { label: "Exit/Restore", key: "exit_restore", permissionFor: ["view"] },
  {
    label: "Child Document",
    key: "child_document",
    permissionFor: ["delete"],
  },
  {
    label: "Social Protection Scheme",
    key: "sps",
    permissionFor: ["delete"],
  },
];

export const permissionLabelclass = {
  all: "checkbox-green",
  create: "checkbox-grey",
  view: "checkbox-blue",
  edit: "checkbox-yellow",
  delete: "checkbox-red",
};

export const rolePermissionsFormFixture = {
  child_tracking: {
    all: false,
    create: false,
    edit: false,
    view: false,
    delete: false,
  },
  child_registration: {
    all: false,
    create: false,
    edit: false,
    view: false,
    delete: false,
  },
  cwc: { all: false, create: false, edit: false, view: false, delete: false },
  exit_restore: { view: false },
};
