import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import {
  SPSTableHeaderFixture,
  SpsDetailsFixture,
  formFixture,
} from "./utilities/fixture";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { isActionAllowed, onChangeInputData } from "../../utilities/helpers";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { toast } from "react-toastify";

const Sps = () => {
  const [loading, setLoading] = useState(false);
  const [spsData, setSpsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [spsDetails, setSpsDetails] = useState(SpsDetailsFixture);

  const getSpsData = async (page_num) => {
    if (loading) return;
    setLoading(true);

    const { res } = await postDataWithAuth({
      api_url: page_num ? endpoints["getSps"](page_num) : endpoints["getSps"](),
      data: {},
    });
    setLoading(false);
    if (res?.success && res?.data) {
      setSpsData(res?.data);
      if (res?.pagination) setPaginationData(res.pagination);
    }
  };

  const handleEdit = (data) => {
    setSpsDetails({
      id: data["id"],
      name: data["name"],
      status: data["status"],
    });
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      const { success, message } = await postDataWithAuth({
        api_url: spsDetails["id"]
          ? endpoints["updateSps"]
          : endpoints["addSps"],
        data: { ...spsDetails },
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        await getSpsData(paginationData?.current_page);
        setOpenModal(false);
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const handleModalClose = () => {
    setSpsDetails(SpsDetailsFixture);
    setOpenModal(false);
  };

  const handleValidation = () => {
    if (spsDetails.name) return true;
    else toast.error("Scheme name is required.");
  };

  useEffect(() => {
    getSpsData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="SPS Management"
          tableHeadersFixture={SPSTableHeaderFixture}
          data={spsData}
          loading={loading}
          startSerialNum={paginationData?.from}
          onEdit={(rowData) => handleEdit(rowData)}
          showPagination={true}
          onPageSelect={(selected) => getSpsData(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
        >
          <div className="button-group filter-button justify-content-md-end">
            {isActionAllowed("create") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => setOpenModal(true)}
              >
                Add
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        header={spsDetails["id"] ? "Update Tag" : "Add Tag"}
        modal_classes="add-role-popup filter-popup-medium"
      >
        <Form
          fixture={formFixture}
          inputs={spsDetails}
          loading={loading}
          onChange={(e) => onChangeInputData(e, spsDetails, setSpsDetails)}
          onSubmit={handleSubmit}
          handleBack={handleModalClose}
          showBackBtn={true}
          backBtnText="Close"
          backBtnClasses="btn-primary-border"
          nextBtnText={"Save"}
          BtnParentClasses="justify-content-end mt-3"
        />
      </Modal>
    </>
  );
};

export default Sps;
