import React, { useEffect, useState } from "react";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import {
  capitalizeWords,
  getCenterNames,
  onChangeInputData,
} from "../../utilities/helpers";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { filterFormFixture, filterInputFixture } from "./utilities/fixture";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const GroupReport2 = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [filterInputs, setFilterInputs] = useState(
    filterInputFixture(logInUser)
  );
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const navigate = useNavigate();

  const getData = async (filterData = {}) => {
    if (loading) return;
    setLoading(true);
    const { success, data, res } = await postDataWithAuth({
      api_url: endpoints["groupReport2"],
      data: filterData,
    });
    setLoading(false);
    if (success && data) {
      setData(data);
      if (res?.date_range) setDateRange(res.date_range);
      if (openModal) setOpenModal(false);
    } else if (filterData) {
      toast.error("Something went wrong!");
    }
  };

  const createHeaders = (list) => {
    let arr = ["S.No", "Headings"];
    for (let i = 0; i < list.length; i++) {
      arr.push(list[i]?.name);
    }
    arr.push("Total");
    setHeaders(arr);
  };

  const getRowData = (key, ind, lastKey) => {
    let arr = [];
    if (key.includes("previous_balance_")) {
      arr.push(" ");
      arr.push(capitalizeWords(key, "_"));
    } else {
      arr.push(ind);
      arr.push(capitalizeWords(key, "_"));
    }
    for (let i = 0; i < Object.keys(data[key]).length; i++) {
      let rowData = data[key][i];
      if (key.includes("total_")) {
        let lastRowData = data[lastKey][i];
        if (lastRowData?.centerId === rowData?.centerId)
          rowData = {
            ...rowData,
            searchParams: lastRowData?.searchParams,
            isTotal: true,
          };
      }
      if (i in data[key]) arr.push(rowData);
    }
    if ("totalsum" in data[key]) arr.push(data[key]["totalsum"]);
    if (key.includes("previous_balance_")) setPrevData(arr);
    return arr;
  };

  const getTableData = () => {
    let rows = [];
    let arr = [];
    let ind = 1;
    let lastKey = "";
    for (let key in data) {
      if (key !== "AllCenterList" && !key.includes("previous_balance_")) {
        let lastKeyData = "";
        if (key.includes("total_")) lastKeyData = lastKey;
        else lastKeyData = "";
        arr = getRowData(key, ind, lastKeyData);
        ind++;
        rows.push(arr);
      }
      lastKey = key;
    }
    setTableData(rows);
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, filterInputs, setFilterInputs);
  };

  const handleSubmit = () => {
    if (loading || !handleValidation()) return;
    getData(filterInputs);
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterInputs["from_date"];
    let to = filterInputs["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterInputs["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  const handleReset = () => {
    setFilterInputs(filterInputFixture(logInUser));
    getData(filterInputFixture(logInUser));
  };

  useEffect(() => {
    async function fetchData() {
      const centerData = await getCenterNames();
      if (centerData) setCenterNames(centerData);
    }
    fetchData();
    getData(filterInputFixture(logInUser));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data["AllCenterList"]) createHeaders(data["AllCenterList"]);
    if (data) {
      let prev_key = "";
      for (let key in data) {
        if (key.includes("previous_balance_")) {
          prev_key = key;
          break;
        }
      }
      if (prev_key) getRowData(prev_key);
    }
    if (data) getTableData();
  }, [data]); // eslint-disable-line

  useEffect(() => {
    let rows = [];
    let prevBalance = [];
    for (let i = 0; i < tableData.length; i++) {
      let row = [];
      for (let j = 0; j < tableData[i].length; j++) {
        if (j < 2) row.push(tableData[i][j]);
        else row.push(tableData[i][j].total);
      }
      rows.push(row);
    }
    for (let i = 0; i < prevData.length; i++) {
      if (i < 2) prevBalance.push(prevData[i]);
      else prevBalance.push(prevData[i].total);
    }
    rows.unshift(headers, prevBalance);
    setCsvData(rows);
  }, [headers, prevData, tableData]);

  return (
    <>
      <main className="inner">
        <div className="bg-white">
          <div className="filter-header px-4 px-lg-4 py-3 border-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-4">
                <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                  Report List
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <div className="button-group filter-button justify-content-md-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary-border"
                    onClick={() => (loading ? {} : setOpenModal(true))}
                  >
                    Filters
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ gap: 0 }}
                  >
                    <CSVLink
                      data={csvData}
                      style={{ color: "#fff" }}
                      filename={"group-report.csv"}
                    >
                      Export
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="filter-list">
              <div className="table-responsive">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader color="#3b3f51" />
                  </div>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {headers.map((header, key) => (
                          <th key={key}>{header}</th>
                        ))}
                      </tr>
                      <tr>
                        {prevData &&
                          prevData.map((header, key) => (
                            <th key={key}>
                              {key > 1 ? header?.total : header}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        tableData.map((arr, key1) => (
                          <tr
                            key={key1}
                            style={
                              arr[1] && arr[1]?.includes("Total")
                                ? { backgroundColor: "#f2f4f6" }
                                : {}
                            }
                          >
                            {arr.map((item, key2) => (
                              <td key={key2}>
                                {key2 > 1 ? (
                                  item?.total > 0 && key2 < arr.length - 1 ? (
                                    <span
                                      style={{ color: "#3598dc" }}
                                      role="button"
                                      onClick={() =>
                                        navigate("report-details", {
                                          state: {
                                            centerId: item?.centerId,
                                            searchparams: item?.searchParams,
                                            isTotal: item?.isTotal,
                                            date_range: dateRange,
                                          },
                                        })
                                      }
                                    >
                                      {item?.total}
                                    </span>
                                  ) : (
                                    item?.total
                                  )
                                ) : (
                                  item
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        open={openModal && !loading}
        onClose={() => setOpenModal(false)}
        header={`Report List Filters`}
        modal_classes="filter-popup-medium"
      >
        <Form
          fixture={filterFormFixture({ centerNames: centerNames }, logInUser)}
          inputs={filterInputs}
          loading={loading}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          handleBack={handleReset}
          nextBtnText="Search"
          nextBtnClasses="btn-search"
          backBtnText="Reset"
          backBtnClasses="btn-primary-border btn-reset"
          BtnParentClasses="justify-content-end mt-3"
          showBackBtn={true}
        />
      </Modal>
    </>
  );
};

export default GroupReport2;
