import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AddCenter from "./Pages/Services/AddCenter";
import ChildRegistration from "./Pages/ChildRegistration/ChildRegistration";
import CenterManagement from "./Pages/Services/CenterManagement";
import DashboardLayout from "./Pages/Dashboard/DashboardLayout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login";
import RolesManagement from "./Pages/Roles/RolesManagement";
import Toaster from "./components/Toaster";
import TagManagement from "./Pages/Services/TagManagement";
import UserManagement from "./Pages/Users/UserManagement";
import ChildProfile from "./Pages/ChildRegistration/ChildProfile";
import Produce from "./Pages/CwcManagement/Produce";
import AboutUs from "./Pages/AboutUs/AboutUs";
import AddProduce from "./Pages/CwcManagement/AddProduce";
import ReferCwc from "./Pages/CwcManagement/ReferCwc";
// import Beneficiary from "./Pages/Services/Beneficiary";
import Manage from "./Pages/Restoration-Exit-Management/Restore-Exit-Manage";
// import GroupReport from "./Pages/Reports/GroupReport";
import Reports from "./Pages/Reports/Reports";
import ReportDetail from "./Pages/Reports/ReportDetail";
import MealManagement from "./Pages/Services/MealManagement";
// import OtherList from "./Pages/Services/OtherList";
import "./App.css";
import "../src/assets/css/adminlte.min.css";
import "../src/assets/css/all.min.css";
import "../src/assets/css/bootstrap-icons.min.css";
import "../src/assets/css/fonts.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/developer.css";
import GroupReport2 from "./Pages/Reports/GroupReport2";
import NotificationList from "./Pages/Notifications/NotificationList";
import { isActionAllowed } from "./utilities/helpers";
import GroupReportDetail from "./Pages/Reports/GroupReportDetail";
import Sps from "./Pages/SPS/Sps";

function App() {
  const ProtectedRoute = ({ children, allowedRoles }) => {
    let userDetails = JSON.parse(localStorage.getItem("user")) || {};
    if (!userDetails?.token) {
      return <Navigate to="/login" />;
    }
    if (allowedRoles.includes("exit_restore")) {
      if (!isActionAllowed("view", "exit_restore")) return <Navigate to="/" />;
    } else if (!allowedRoles.includes(userDetails?.role?.name)) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const routesConfig = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/notifications",
          element: <NotificationList />,
        },
        {
          path: "/tag-management",
          allowedRoles: ["admin", "superadmin"],
          element: <TagManagement />,
        },
        {
          path: "/meals-management",
          allowedRoles: ["admin", "superadmin", "viewer"],
          element: <MealManagement />,
        },
        // {
        //   path:"/education",
        //   element:<Beneficiary />
        // },
        // {
        //   path:"/medical",
        //   element:<Beneficiary />
        // },
        // {
        //   path:"mhp",
        //   element:<Beneficiary />
        // },
        {
          path: "education",
          allowedRoles: ["admin", "superadmin", "viewer"],
          // element: <OtherList />,
          element: <Manage />,
        },
        {
          path: "medical",
          allowedRoles: ["admin", "superadmin", "viewer"],
          // element: <OtherList />,
          element: <Manage />,
        },
        {
          path: "mhp",
          allowedRoles: ["admin", "superadmin", "viewer"],
          // element: <OtherList />,
          element: <Manage />,
        },
        {
          path: "/roles-management",
          allowedRoles: ["admin", "superadmin"],
          element: <RolesManagement />,
        },
        {
          path: "/sps-management",
          allowedRoles: ["admin", "superadmin"],
          element: <Sps />,
        },
        {
          path: "/users-management",
          allowedRoles: ["admin", "superadmin"],
          element: <UserManagement />,
        },
        {
          path: "/restoration",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        // {
        //   path: "/restoration-followup",
        //   allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
        //   element: <Manage />,
        // },
        {
          path: "/followup",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/rehabilitation-set-free",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/run-away",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/after-care",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/higher-education",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/dropout",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/short-stay",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/short-stay-register",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        // {
        //   path: "/transfer",
        //   allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
        //   element: <Manage />,
        // },
        {
          path: "/other-ngo",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/io-police",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/home-state-cwc-transfer",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        // {
        //   path: "/cwc-dropout",
        //   element: <Manage />,
        // },
        // {
        //   path: "/cwc-after-care",
        //   element: <Manage />,
        // },
        // {
        //   path: "/cwc-higher-education",
        //   element: <Manage />,
        // },
        // {
        //   path: "/cwc-rehabilitation",
        //   element: <Manage />,
        // },
        // {
        //   path: "/cwc-run-away",
        //   element: <Manage />,
        // },
        // {
        //   path: "/cwc-short-stay",
        //   element: <Manage />,
        // },
        {
          path: "/cwc-by-police",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/cwc-by-staff",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/cwc-by-third-party",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/cwc-rescue",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/cwc-other-cci",
          allowedRoles: ["admin", "superadmin", "viewer", "exit_restore"],
          element: <Manage />,
        },
        {
          path: "/aboutus",
          element: <AboutUs />,
        },
        // {
        //   path: "/groupReports",
        //   allowedRoles: ["admin", "superadmin", "viewer"],
        //   // element: <GroupReport />,
        //   element: <GroupReport2 />,
        // },
      ],
    },
    {
      path: "/child-registration",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <ChildRegistration />,
        },
        {
          path: "profile",
          element: <ChildProfile />,
        },
      ],
    },
    {
      path: "/produce",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <Produce />,
        },
        {
          path: "add",
          element: <AddProduce />,
        },
      ],
    },
    {
      path: "/refer",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <ReferCwc />,
        },
        {
          path: "add",
          element: <AddProduce />,
        },
      ],
    },
    {
      path: "/reports",
      element: <DashboardLayout />,
      allowedRoles: ["admin", "superadmin", "viewer"],
      children: [
        {
          path: "",
          element: <Reports />,
        },
        {
          path: "report-details",
          element: <ReportDetail />,
        },
      ],
    },
    {
      path: "/groupReports",
      element: <DashboardLayout />,
      // allowedRoles: ["admin", "superadmin", "viewer"],
      children: [
        {
          path: "",
          element: <GroupReport2 />,
        },
        {
          path: "report-details",
          element: <GroupReportDetail />,
        },
      ],
    },
    {
      path: "/center-management",
      element: <DashboardLayout />,
      allowedRoles: ["admin", "superadmin"],
      children: [
        {
          path: "",
          element: <CenterManagement />,
        },
        {
          path: "add-center",
          element: <AddCenter />,
        },
      ],
    },
  ];

  return (
    <div className="App">
      <Toaster />
      <BrowserRouter basename="/">
        <Routes>
          {routesConfig.map(({ path, element, allowedRoles, children }) => (
            <Route
              key={path}
              path={path}
              element={
                allowedRoles ? (
                  <ProtectedRoute allowedRoles={allowedRoles}>
                    {element}
                  </ProtectedRoute>
                ) : (
                  element
                )
              }
            >
              {children &&
                children.map(
                  ({
                    path: childPath,
                    allowedRoles: childAllowedRoles,
                    element: childElement,
                  }) => (
                    <Route
                      key={childPath}
                      path={childPath}
                      element={
                        childAllowedRoles ? (
                          <ProtectedRoute allowedRoles={childAllowedRoles}>
                            {childElement}
                          </ProtectedRoute>
                        ) : (
                          childElement
                        )
                      }
                    />
                  )
                )}
            </Route>
          ))}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
