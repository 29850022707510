export const filterFormFixture = (optionData, userDetails) => {
  return [
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select",
        name: "centerId",
      },
      parentClassName: "row mb-3",
      className: "col-12",
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
      row: "new",
      readOnlyField: userDetails?.centerId === 1 ? false : true,
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "from_date",
      },
      className: "col-12",
      label: {
        text: "From",
        labelProps: { htmlFor: "from_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "to_date",
      },
      className: "col-12",
      label: {
        text: "To",
        labelProps: { htmlFor: "to_date", className: "" },
      },
    },
  ];
};

export const filterInputFixture = (data) => ({
  centerId: data?.centerId || "",
  from_date: "",
  to_date: "",
});

export const singleReportHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", key: "name" },
  { text: "Unique Id", key: "uniqueId" },
  { text: "Center Unique Id", key: "unique_centerId" },
  { text: "Status", key: "history_status", formatVal: true },
  { text: "Aadhar No.", key: "aadharno" },
  { text: "CWC Case No.", key: "cwccaseno" },
  { text: "Gender", key: "gender" },
  { text: "Center Name", objKey: "center_data", key: "name" },
  { text: "Date Of Arrival", key: "dateofarrival" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
];

export const singleGroupReportHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", key: "name" },
  { text: "Unique Id", key: "uniqueId" },
  { text: "Aadhar No.", key: "aadharno" },
  { text: "Status", key: "history_status", formatVal: true },
  { text: "CWC Case No.", key: "cwccaseno" },
  { text: "Gender", key: "gender" },
  { text: "Center Name", key: "center_name" },
  { text: "Date Of Arrival", key: "dateofarrival" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
];
