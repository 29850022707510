import React from "react";
import CustomPagination from "../Pagination/CustomPagination";
import DataTable from "./DataTable";
import { noRecordFound } from "../../assets/images";

const TableArea = ({
  children,
  heading = "",
  tableHeadersFixture = [],
  data = [],
  loading = false,
  keyname = "",
  startSerialNum = 1,
  imagePath = "",
  getKeyVal = () => {},
  onSetPermission = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onView = () => {},
  onGoTo = () => {},
  onStatusChange = () => {},
  onSelectRow = () => {},
  selectedRows = {},
  showPagination = false,
  items = [],
  onPageSelect = () => {},
  paginationData = {},
  perPage = 10,
  currPage = 1,
  showNotFound = true,
  filePath = "",
  audioFilePath = "",
  deleteLastOnly = false,
  hideAction = false,
}) => {
  return (
    <>
      <div className="bg-white">
        {heading && (
          <div className="filter-header px-4 px-lg-4 py-3 border-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-4">
                <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                  {heading}
                </h5>
              </div>
              <div className="col-12 col-md-8">{children}</div>
            </div>
          </div>
        )}

        <div className="p-4">
          <div className="filter-list">
            <DataTable
              tableHeadersFixture={tableHeadersFixture}
              data={data}
              keyname={keyname}
              loading={loading}
              startSerialNum={startSerialNum}
              imagePath={imagePath}
              getKeyVal={getKeyVal}
              onDelete={(rowData) => onDelete(rowData)}
              onEdit={(rowData) => onEdit(rowData)}
              onView={(rowData) => onView(rowData)}
              onGoTo={(rowData) => onGoTo(rowData)}
              onSetPermission={(rowData) => onSetPermission(rowData)}
              onStatusChange={onStatusChange}
              onSelectRow={onSelectRow}
              selectedRows={selectedRows}
              filePath={filePath}
              audioFilePath={audioFilePath}
              deleteLastOnly={deleteLastOnly}
              hideAction={hideAction}
            />
          </div>
          {showPagination && (
            <CustomPagination
              currPage={currPage}
              perPage={perPage}
              items={items.length ? items : data}
              onPageSelect={onPageSelect}
              paginationData={paginationData}
            />
          )}
          {!(data.length || items.length) && showNotFound && (
            <div className="no-record-main">
              <div className="img-no">
                <img src={noRecordFound} className="mw-100" alt="" />
              </div>
              <h5>No Records Found!</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableArea;
