import React, { useEffect, useState } from "react";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import {
  capitalizeWords,
  getCenterNames,
  onChangeInputData,
} from "../../utilities/helpers";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { filterFormFixture, filterInputFixture } from "./utilities/fixture";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const Reports = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [filterInputs, setFilterInputs] = useState(
    filterInputFixture(logInUser)
  );
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [reportDetailData, setReportDetailData] = useState({});
  const [centername, setCentername] = useState("");

  const navigate = useNavigate();

  const getData = async (filterData = {}) => {
    if (loading) return;
    setLoading(true);
    const { success, data } = await postDataWithAuth({
      api_url: endpoints["reports"],
      data: filterData,
    });
    setLoading(false);
    if (success && data) {
      setData(data);
      if (openModal) setOpenModal(false);
    } else if (filterData) {
      toast.error("Something went wrong!");
    }
  };

  const createHeaders = () => {
    let arr = ["S.No", "All Center Records"];
    let start_year = data["start_year"] || "";
    let end_year = data["end_year"] || "";
    let prev_start_year = data["pre_start_year"] || "";
    let prev_end_year = data["pre_end_year"] || "";

    ["M", "F", "Total"].map((item) =>
      arr.push(`${item} (${start_year} - ${end_year})`)
    );

    ["M", "F", "Total"].map((item) =>
      arr.push(
        `${item}(Previous Year Record ${prev_start_year} - ${prev_end_year})`
      )
    );

    ["M", "F", "Total"].map((item) =>
      arr.push(
        `${item}(Previous Years Record till 31th March ${prev_end_year})`
      )
    );

    arr.push("Total Male");
    arr.push("Total Female");
    arr.push("Grand Total");
    setHeaders(arr);
  };

  const getRowData = (key, ind) => {
    let arr = [];

    if (key === "previousyeardata") {
      arr.push(" ");
      arr.push(
        `Previous Balance (${data["pre_start_year"]} - ${data["pre_end_year"]})`
      );
    } else {
      arr.push(ind);
      arr.push(capitalizeWords(key, "_"));
    }

    let curr = data[key].new;
    let prev = data[key].pre;
    let till = data[key].till;
    let arr1 =
      key === "previousyeardata" ? getPYCount(curr) : getCount(curr, "new");
    let arr2 =
      key === "previousyeardata" ? getPYCount(prev) : getCount(prev, "pre");
    let arr3 =
      key === "previousyeardata" ? getPYCount(till) : getCount(till, "till");
    arr.push(...arr1);
    arr.push(...arr2);
    arr.push(...arr3);
    if (key === "previousyeardata") {
      for (let i = 0; i < 3; i++) {
        arr.push(parseInt(arr1[i]) + parseInt(arr2[i]) + parseInt(arr3[i]));
      }
      setPrevData(arr);
    } else {
      for (let i = 0; i < 3; i++) {
        arr.push({
          countgender:
            parseInt(arr1[i]?.countgender) +
            parseInt(arr2[i]?.countgender) +
            parseInt(arr3[i]?.countgender),
        });
      }
    }

    return arr;
  };

  const getCount = (arr, dataFor = "") => {
    let male_count = 0;
    let female_count = 0;
    let total = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.gender === "Male") male_count = arr[i]?.countgender;
      else if (arr[i]?.gender === "Female") female_count = arr[i]?.countgender;
    }
    total = male_count + female_count;

    let maleData = typeof arr[0] === "object" ? { ...arr[0], dataFor } : {};
    let femaleData = typeof arr[1] === "object" ? { ...arr[1], dataFor } : {};
    let totalData = {
      dataFor: dataFor,
      gender: "both",
      countgender: total,
      search_1: arr[0]?.search_1 || {},
      search_2: arr[0]?.search_2 || {},
    };

    return [maleData, femaleData, totalData];
  };

  const getPYCount = (obj) => {
    let male_count = obj?.male || 0;
    let female_count = obj?.female || 0;
    let total = obj?.countgender || 0;
    return [male_count, female_count, total];
  };

  const getTableData = () => {
    let rows = [];
    let arr = [];
    let ind = 1;
    for (let key in data) {
      if (key === "center_name") setCentername(data[key]);
      else if (
        ["from_date", "to_date", "centerId", "from_last", "to_last"].includes(
          key
        )
      )
        setReportDetailData((prev) => ({ ...prev, [key]: data[key] }));
      else if (
        key !== "previousyeardata" &&
        typeof data[key] !== "string" &&
        typeof data[key] !== "number"
      ) {
        arr = getRowData(key, ind);
        ind++;
        rows.push(arr);
      }
    }
    setTableData(rows);
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, filterInputs, setFilterInputs);
  };

  const handleSubmit = () => {
    if (loading || !handleValidation()) return;
    getData(filterInputs);
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterInputs["from_date"];
    let to = filterInputs["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterInputs["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  const handleReset = () => {
    setFilterInputs(filterInputFixture(logInUser));
    getData(filterInputFixture(logInUser));
  };

  useEffect(() => {
    async function fetchData() {
      const centerData = await getCenterNames();
      if (centerData) setCenterNames(centerData);
    }
    fetchData();
    getData(filterInputFixture(logInUser));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data) {
      createHeaders();
      if (data["previousyeardata"]) getRowData("previousyeardata");
      getTableData();
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    let rows = [];
    for (let i = 0; i < tableData?.length; i++) {
      let row = [];
      for (let j = 0; j < tableData[i]?.length; j++) {
        if (j > 1) row.push(tableData[i][j]?.countgender);
        else row.push(tableData[i][j]);
      }
      rows.push(row);
    }
    rows.unshift(headers, prevData);
    setCsvData(rows);
  }, [headers, prevData, tableData]);

  useEffect(() => {
    let arr = [];
    if (centername) {
      arr = [...headers];
      arr[1] = centername === "Admin" ? "All Center Records" : centername;
    }
    setHeaders(arr);
  }, [centername]); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <div className="bg-white">
          <div className="filter-header px-4 px-lg-4 py-3 border-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-4">
                <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                  Report List
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <div className="button-group filter-button justify-content-md-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary-border"
                    onClick={() => (loading ? {} : setOpenModal(true))}
                  >
                    Filters
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ gap: 0 }}
                  >
                    <CSVLink
                      data={csvData}
                      style={{ color: "#fff" }}
                      filename={"report.csv"}
                    >
                      Export
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="filter-list">
              <div className="table-responsive">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader color="#3b3f51" />
                  </div>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {headers.map((header, key) => (
                          <th key={key}>{header}</th>
                        ))}
                      </tr>
                      <tr>
                        {data["previousyeardata"] &&
                          prevData.map((header, key) => (
                            <th key={key}>{header}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        tableData.map((arr, key1) => (
                          <tr key={key1}>
                            {arr.map((item, key2) => (
                              <td key={key2}>
                                {typeof item === "object" ? (
                                  item?.countgender > 0 &&
                                  key2 > 1 &&
                                  key2 < 8 ? (
                                    <span
                                      style={{ color: "#3598dc" }}
                                      role="button"
                                      onClick={() =>
                                        navigate("report-details", {
                                          state: {
                                            ...item,
                                            ...reportDetailData,
                                          },
                                        })
                                      }
                                    >
                                      {item?.countgender}
                                    </span>
                                  ) : (
                                    item?.countgender || 0
                                  )
                                ) : (
                                  item
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        open={openModal && !loading}
        onClose={() => setOpenModal(false)}
        header={`Report List Filters`}
        modal_classes="filter-popup-medium"
      >
        <Form
          fixture={filterFormFixture({ centerNames: centerNames }, logInUser)}
          inputs={filterInputs}
          loading={loading}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          handleBack={handleReset}
          nextBtnText="Search"
          nextBtnClasses="btn-search"
          backBtnText="Reset"
          backBtnClasses="btn-primary-border btn-reset"
          BtnParentClasses="justify-content-end mt-3"
          showBackBtn={true}
        />
      </Modal>
    </>
  );
};

export default Reports;
