import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TableArea from "../../components/DataTable/TableArea";
import { singleGroupReportHeaderFixture } from "./utilities/fixture";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { toast } from "react-toastify";
import { downIcon } from "../../assets/images";

const GroupReportDetail = () => {
  const location = useLocation();
  const endpoint_data = location.state;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    if (loading || !endpoint_data?.centerId) return;
    setLoading(true);
    let body = {
      centerId: endpoint_data?.centerId || "novalue",
      from_date: endpoint_data?.date_range?.from_date || "novalue",
      to_date: endpoint_data?.date_range?.to_date || "novalue",
      pre_end_year: endpoint_data?.date_range?.pre_end_year || "novalue",
      pre_start_year: endpoint_data?.date_range?.pre_start_year || "novalue",
      where: endpoint_data?.searchparams?.search_1?.where || "novalue",
      value: endpoint_data?.searchparams?.search_1?.value || "novalue",
      where1: endpoint_data?.isTotal
        ? "novalue"
        : endpoint_data?.searchparams?.search_2?.where || "novalue",
      value1: endpoint_data?.isTotal
        ? "novalue"
        : endpoint_data?.searchparams?.search_2?.value || "novalue",
      isTotal: endpoint_data?.isTotal || false,
    };
    const { success, data } = await postDataWithAuth({
      api_url: endpoints["groupSingleReport"],
      data: body,
    });
    setLoading(false);
    if (success && data) {
      setList(data);
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, [endpoint_data]); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <div className="bg-white mb-3">
          <div className="px-4 py-3 border-bottom header-page">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="d-flex align-items-center mb-3 mb-md-0">
                  <span className=" btn btn-back" onClick={() => navigate(-1)}>
                    <img src={downIcon} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <TableArea
            tableHeadersFixture={singleGroupReportHeaderFixture}
            data={list}
            loading={loading}
          ></TableArea>
        </div>
      </main>
    </>
  );
};

export default GroupReportDetail;
