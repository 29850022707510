import React, { useEffect, useState } from "react";
import { getDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import TableArea from "../../components/DataTable/TableArea";
import { notifyListTableFixture } from "./utilities/fixture";
import { useNavigate } from "react-router-dom";

const NotificationList = () => {
  const [logInUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [loading, setLoading] = useState(false);
  const [notifyList, setNotifyList] = useState([]);
  const navigate = useNavigate();

  const getNofificationList = async (centerId) => {
    if (loading || !centerId) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: endpoints["notifyList"](centerId),
    });
    setLoading(false);
    if (success && data) setNotifyList(data);
  };

  const markAsRead = async (rowData) => {
    if (loading || !rowData?.id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["notifyUpdate"](rowData.id),
    });
    setLoading(false);
    if (success)
      setNotifyList((prev) => prev.filter((item) => item.id !== rowData.id));
  };

  const getChildInfo = async (id, action) => {
    navigate("/child-registration/profile", {
      state: { data: { id }, action },
    });
  };

  useEffect(() => {
    if (logInUser?.centerId) getNofificationList(logInUser?.centerId);
  }, [logInUser]); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading=""
          tableHeadersFixture={notifyListTableFixture}
          data={notifyList}
          loading={loading}
          onView={(rowData) => markAsRead(rowData)}
          onEdit={(rowData) => getChildInfo(rowData["childregId"], "edit")}
        ></TableArea>
      </main>
    </>
  );
};

export default NotificationList;
