export const SPSTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Social Protection Scheme", key: "name" },
  {
    text: "Status",
    fn: (item) =>
      item?.status ? (
        <span className="tag-corner">Active</span>
      ) : (
        <span
          className="tag-corner"
          style={{
            color: "#cb5a5e",
          }}
        >
          Inactive
        </span>
      ),
  },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Action", action: true, key: ["edit"] },
];

export const SpsDetailsFixture = {
  name: "",
  status: 1,
};

export const formFixture = [
  {
    inputProps: {
      type: "text",
      className: "form-control",
      placeholder: "Enter Scheme Name",
      name: "name",
    },
    className: "col-12",
    parentClassName: "row mb-3",
    label: {
      text: "Scheme Name",
      labelProps: { htmlFor: "name", className: "" },
    },
    row: "new",
    required: true,
  },
  {
    inputProps: {
      type: "select",
      className: "form-control",
      placeholder: "Select",
      name: "status",
    },
    className: "col-12",
    parentClassName: "row mb-3",
    options: [
      { text: "Enable", value: 1 },
      { text: "Disable", value: 0 },
    ],
    label: {
      text: "Status",
      labelProps: { htmlFor: "status", className: "" },
    },
    hideSelectOption: true,
    row: "new",
    required: true,
  },
];
