import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import {
  getCityList,
  getCountryList,
  getDistrictList,
  getStateList,
  onChangeInputData,
} from "../../utilities/helpers";
import Form from "../../components/Form/Form";
import { FilterFormFixture, filterInputFixture } from "./utilities/fixtures";
import { toast } from "react-toastify";

const FilterRegistrations = ({
  openModal = false,
  setOpenModal = () => {},
  filterDetails = {},
  setFilterDetails = () => {},
  centerNames = [],
  loading = false,
  getAllRegistrations = () => {},
  userDetails = {},
  applyFilter = () => {},
}) => {
  const [formFixture, setFormFixture] = useState(FilterFormFixture());
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const handleModalClose = (reset) => {
    if (reset) setFilterDetails(filterInputFixture(userDetails));
    setOpenModal(false);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name !== "countryId" && name !== "stateId")
      onChangeInputData(e, filterDetails, setFilterDetails);

    if (name === "countryId" && value) {
      let obj = {
        stateId: "",
        cityId: "",
      };
      onChangeInputData(e, filterDetails, setFilterDetails, {
        overrideKeys: obj,
      });
      const data = await getStateList(value);
      if (data) setStates(data);
    } else if (name === "stateId" && value) {
      onChangeInputData(e, filterDetails, setFilterDetails, {
        overrideKeys: { cityId: "", district: "" },
      });
      const data = await getCityList(value);
      if (data) setCities(data);

      const district_data = await getDistrictList(value);
      if (district_data) setDistricts(district_data);
    }
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterDetails["from_date"];
    let to = filterDetails["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterDetails["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  const filterRegistrations = async (reset = false) => {
    if (reset) {
      setFilterDetails(filterInputFixture(userDetails));
      applyFilter(false);
      getAllRegistrations(1, false, handleModalClose);
    } else if (handleValidation())
      getAllRegistrations(1, filterDetails, handleModalClose);
  };

  useEffect(() => {
    let optionData = {
      countries: countries,
      states: states,
      cities: cities,
      district: districts,
      centerNames: centerNames,
    };
    setFormFixture(FilterFormFixture(optionData, userDetails));
  }, [countries, states, cities, districts, centerNames, userDetails]);

  useEffect(() => {
    async function fetchData() {
      const countryData = await getCountryList();
      if (countryData) setCountries(countryData);
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <Modal
      open={openModal}
      onClose={() => handleModalClose(true)}
      header="Search User Details Filters"
    >
      <Form
        fixture={formFixture}
        inputs={filterDetails}
        loading={loading}
        onChange={handleInputChange}
        onSubmit={filterRegistrations}
        handleBack={filterRegistrations}
        showBackBtn={true}
        backBtnText="Reset"
        backBtnClasses="btn-primary-border btn-reset"
        nextBtnText="Search"
        nextBtnClasses="btn-search"
        BtnParentClasses="justify-content-end mt-3"
      />
    </Modal>
  );
};

export default FilterRegistrations;
