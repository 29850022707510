import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "../../components/Form/Form";
import { userAvatar } from "../../assets/images";
import {
  getCenterNames,
  getCityList,
  getCountryList,
  getDistrictList,
  getStateList,
  getTagNames,
  handleFileDownload,
  isActionAllowed,
  onChangeInputData,
} from "../../utilities/helpers";
import {
  ChildRegisterFormsFixture,
  childProfileNavFixture,
  childRegisterInputFixture,
  documentsInputFixture,
  profileTabFixture,
  registerNumberOnlyInputs,
  registerValidationField,
} from "./utilities/fixtures";
import { toast } from "react-toastify";
import { endpoints } from "../../_api/endpoints";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import MoreDetails from "./MoreDetails";
import ActionBtn from "./ActionBtn";
import validate from "../../utilities/validation";
import FollowUps from "./FollowUps";

const ChildProfile = () => {
  const location = useLocation();
  let childinfo = location.state;

  const [info, setInfo] = useState(childinfo); // eslint-disable-line
  const [childInfo, setChildInfo] = useState({});
  const newRegistration = info ? false : true;
  const [registerFormFixture, setRegisterFormFixture] = useState(
    ChildRegisterFormsFixture()[0]
  );
  const [registerFormInputs, setRegisterFormInputs] = useState(
    childRegisterInputFixture(JSON.parse(localStorage.getItem("user")) || {})
  );

  const [documentInputs, setDocumentInputs] = useState([documentsInputFixture]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [centerNames, setCenterNames] = useState([]);
  const [tags, setTags] = useState([]);
  const [progress, setProgress] = useState(0);
  const [displayProfilePic, setDisplayProfilePic] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [childCenterName, setChildCenterName] = useState("");
  const [title, setTitle] = useState("New Registration");
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [documentPath, setDocumentPath] = useState("");
  const [audioPath, setAudioPath] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [childStatus, setChildStatus] = useState({});
  const [loading2, setLoading2] = useState(false);

  const navigate = useNavigate();

  const handleDocInputChange = (e, ind) => {
    onChangeInputData(e, documentInputs, setDocumentInputs, {
      index: ind,
    });
  };

  const getChildStatus = async () => {
    if (!childInfo?.data?.id) return;
    const { success, data } = await getDataWithAuth({
      api_url: endpoints["childStatus"](childInfo.data.id),
    });
    if (success) {
      setChildStatus(data);
    }
  };

  const reopenChild = async () => {
    if (loading || !childInfo?.data?.id) return;
    const { success } = await getDataWithAuth({
      api_url: endpoints["reopen"](childInfo.data.id),
    });
    if (success) {
      getChildStatus();
    }
  };

  const addMoreDocuments = () => {
    let c1 = 0;
    for (let i = 0; i < documentInputs.length; i++) {
      if (documentInputs[i]["tagId"] && documentInputs[i]["doc_date"]) c1++;
    }
    if (c1 === documentInputs.length)
      setDocumentInputs([...documentInputs, documentsInputFixture]);
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "short_stay_status") {
      let obj =
        value === "no"
          ? {
              short_stay: "",
            }
          : {};
      onChangeInputData(e, registerFormInputs, setRegisterFormInputs, {
        overrideKeys: obj,
      });
      updateFormFixture(name, value);
    } else if (name !== "countryId" && name !== "stateId") {
      onChangeInputData(e, registerFormInputs, setRegisterFormInputs, {
        numberFields: registerNumberOnlyInputs,
      });
      if (name === "reference_type") updateFormFixture(name, value);
    }
    if (name === "countryId" && value) {
      let obj = {
        stateId: "",
        cityId: "",
        district: "",
      };
      onChangeInputData(e, registerFormInputs, setRegisterFormInputs, {
        overrideKeys: obj,
      });
      const data = await getStateList(value);
      if (data) {
        setFixtureOptions("stateId", data);
        setStates(data);
      }
    } else if (name === "stateId" && value) {
      onChangeInputData(e, registerFormInputs, setRegisterFormInputs, {
        overrideKeys: { cityId: "", district: "" },
      });
      await getStateCityOption("city", value);
    }
  };

  const updateFormFixture = (fieldName, value) => {
    let optionData = {
      [fieldName]: value,
    };
    if (activeTab === 1) {
      optionData = {
        ...optionData,
        countries: countries,
        states: states,
        cities: cities,
        district: districts,
        centerNames: centerNames,
      };
    }
    let obj = ChildRegisterFormsFixture(optionData)[activeTab];
    setRegisterFormFixture(obj);
  };

  const updateOverviewForm = (action) => {
    let arr = ChildRegisterFormsFixture({}, action)[0];

    // arr = arr.map((item) =>
    //   item.inputProps
    //     ? item.inputProps.name === "dateofarrival"
    //       ? { ...item, readOnlyField: true }
    //       : item
    //     : item
    // );
    setRegisterFormFixture(arr);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      if (activeTab === 2 && progress === 2) {
        setProgress(activeTab + 1);
        setActiveTab(activeTab + 1);
        setRegisterFormInputs((prev) => ({
          ...prev,
          documents: documentInputs,
        }));
      } else if (progress < 3) {
        setProgress(activeTab + 1);
        setActiveTab(activeTab + 1);
      } else if (activeTab === 3 && progress === 3) {
        setLoading(true);
        let docs = {};
        let ind = 0;

        for (let key in registerFormInputs.documents) {
          const doc = registerFormInputs.documents[key];
          if (!doc["id"]) {
            for (let key2 in doc) {
              docs[`documents[${ind}][${key2}]`] = doc[key2];
            }
            ind++;
          }
        }
        let inputdata = {};
        let keyData = childRegisterInputFixture();
        for (let key in keyData) {
          inputdata[key] = registerFormInputs[key];
        }
        if (registerFormInputs["id"])
          inputdata["id"] = registerFormInputs["id"];

        const newFormInputs = { ...inputdata, ...docs };
        delete newFormInputs["documents"];
        let formData = new FormData();
        for (let key in newFormInputs) {
          if (key === "image" && typeof newFormInputs["image"] === "object") {
            if (newFormInputs[key]) formData.append(key, newFormInputs[key]);
          } else if (key !== "image" && newFormInputs[key])
            formData.append(key, newFormInputs[key]);
        }
        if (childInfo?.action === "edit") {
          formData.append("old_image", oldImage);
        }
        if (!Object.keys(docs).length) formData.append("documents", "");

        const { success, errors, message } = await postDataWithAuth({
          api_url:
            childInfo?.action === "edit"
              ? endpoints["updateChild"]
              : endpoints["addChild"],
          data: formData,
          contentType: "multipart/form-data",
        });
        setLoading(false);
        if (success) {
          if (message) toast.success(message);
          navigate("/child-registration");
          setProgress(0);
          setActiveTab(0);
        } else if (errors) {
          if (typeof errors === "object")
            toast.error(errors[Object.keys(errors)[0]][0]);
        } else {
          toast.error(message);
        }
      } else {
        setActiveTab(activeTab + 1);
      }
    }
  };

  const handleValidation = () => {
    let validation = true;
    const fieldsToValidate = registerValidationField(activeTab);
    for (let i = 0; i < fieldsToValidate.length; i++) {
      const { key, relatedkey, relatedVal, label, optional } =
        fieldsToValidate[i];
      if (optional) {
        if (registerFormInputs[key]) {
          let err = validate[key](registerFormInputs[key]);
          if (!err) continue;
          if (key === "mobileno" || key === "phoneno")
            toast.error(`${label} must be of 10 digits`);
          else toast.error(err);
          setProgress(activeTab);
          return false;
        }
      } else if (
        (!relatedkey && !registerFormInputs[key]) ||
        (relatedkey &&
          registerFormInputs[relatedkey] === relatedVal &&
          !registerFormInputs[key])
      ) {
        toast.error(`${label} is required`);
        validation = false;
        setProgress(activeTab);
        return false;
      }
    }
    return validation;
  };

  const handleDocumentUpdate = async (docInputs) => {
    if (loading || !docInputs["id"]) return;
    if (validateDoc(docInputs)) {
      let formData = new FormData();
      delete docInputs["tag"];

      for (let key in docInputs) {
        let val = "";
        if (key === "filename" || key === "profile_audio_name") {
          val = typeof docInputs[key] === "object" ? docInputs[key] : "";
        } else val = docInputs[key];
        if (val) formData.append(key, val);
      }

      const { success, message } = await postDataWithAuth({
        api_url: endpoints["updateDocuments"],
        data: formData,
        contentType: "multipart/form-data",
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const validateDoc = (docinput) => {
    let valid = true;
    let req_fields = ["tagId", "doc_date"];
    for (let i = 0; i < req_fields.length; i++) {
      let key = req_fields[i];
      let err = validate[key](docinput[key]);
      if (err) {
        toast.error(err);
        valid = false;
        break;
      }
    }
    return valid;
  };

  const handleDeleteDocuments = async (doc, index) => {
    if (!doc["id"] && index) {
      setDocumentInputs((prev) => prev.filter((_, ind) => index !== ind));
      return;
    }
    if (loading || !doc["id"]) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteDocuemnts"](doc["id"]),
    });
    setLoading(false);
    if (success) {
      toast.success("Document deleted successfully");
      if (documentInputs.length === 1)
        setDocumentInputs([documentsInputFixture]);
      else
        setDocumentInputs((prev) =>
          prev.filter((item) => item.id !== doc["id"])
        );
    } else {
      toast.error("Something went wrong!");
    }
  };

  const setFixtureOptions = (fieldName, data) => {
    let obj =
      fieldName === "countryId"
        ? ChildRegisterFormsFixture({
            countries: countries,
            states: states,
            cities: cities,
            district: districts,
            centerNames: centerNames,
            short_stay_status: registerFormInputs["short_stay_status"],
            countryId: registerFormInputs["countryId"],
          })[activeTab]
        : registerFormFixture;
    obj = obj.map((item) =>
      item.inputProps
        ? item.inputProps.name === fieldName
          ? { ...item, options: [...data] }
          : (item.inputProps.name === "cityId" ||
              item.inputProps.name === "district") &&
            fieldName === "stateId"
          ? { ...item, options: [] }
          : item
        : item
    );
    setRegisterFormFixture(obj);
  };

  const getStateCityOption = async (optionFor, id) => {
    let data = [];
    if (optionFor === "state") {
      data = await getStateList(id);
      if (data) {
        if (activeTab === 1) setFixtureOptions("stateId", data);
        setStates(data);
      }
    } else if (optionFor === "city") {
      // data = await getCityList(id);
      // if (data) {
      //   setFixtureOptions("cityId", data);
      //   setCities(data);
      // }

      const city_data = await getCityList(id);
      if (city_data) setCities(city_data);

      // district data
      const district_data = await getDistrictList(id);
      if (district_data) setDistricts(district_data);

      if (activeTab === 1) {
        setRegisterFormFixture(
          ChildRegisterFormsFixture({
            countries: countries,
            states: states,
            cities: city_data,
            district: district_data,
            centerNames: centerNames,
            short_stay_status: registerFormInputs["short_stay_status"],
            countryId: registerFormInputs["countryId"],
          })[activeTab]
        );
      }
    }
  };
  const getChildInfo = async (id, action, title = "Child’s Information") => {
    if (loading || !id) return;
    setLoading(true);
    const { success, img_profile, image_path, img_documents, data } =
      await getDataWithAuth({
        api_url:
          action === "edit"
            ? endpoints["getEditChildData"](id)
            : endpoints["viewChild"](id),
      });
    setLoading(false);
    if (success) {
      let childInfo = {
        img_profile,
        image_path,
        img_documents,
        data,
        action: action,
      };
      setChildInfo(childInfo);
      setTitle(title);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const toggleAction = () => {
    if (childInfo?.action === "view") {
      setChildInfo((prev) => ({ ...prev, action: "edit" }));
      setProgress(activeTab);
    } else {
      setChildInfo((prev) => ({ ...prev, action: "view" }));
      setProgress(3);
    }
    let action = childInfo?.action === "view" ? "edit" : "view";
    getChildInfo(childInfo?.data?.id, action, title);
  };

  const handleProfileDownload = async (url) => {
    if (loading2) return;
    setLoading2(true);
    await handleFileDownload(url);
    setLoading2(false);
  };

  useEffect(() => {
    if (
      registerFormInputs["image"] &&
      typeof registerFormInputs["image"] === "object"
    )
      setDisplayProfilePic(URL.createObjectURL(registerFormInputs["image"]));
  }, [registerFormInputs]);

  useEffect(() => {
    if (childInfo?.data) {
      // setTitle("Child’s Information");
      setRegisterFormInputs(childInfo.data);
      getChildStatus();
      let profile_pic = childInfo?.data?.image;
      let imagePath = childInfo?.image_path;
      let documents_path = childInfo?.img_documents;
      let profile_audio_path = childInfo?.img_profile;
      setDocumentPath(documents_path);
      setAudioPath(profile_audio_path);
      let documents = [];
      if (profile_pic && imagePath) {
        setOldImage(profile_pic);
        setDisplayProfilePic(`${imagePath}/${profile_pic}`);
      }

      if (childInfo?.data?.countryId)
        getStateCityOption("state", childInfo?.data?.countryId);

      if (childInfo?.data?.stateId)
        getStateCityOption("city", childInfo?.data?.stateId);

      if (childInfo?.action === "view") {
        setProgress(3);
        for (let i = 0; i < childInfo?.data?.documents.length; i++) {
          let doc = { ...childInfo?.data?.documents[i] };
          doc["filename"] = `${documents_path}/${doc["filename"]}`;

          doc[
            "profile_audio_name"
          ] = `${profile_audio_path}/${doc["profile_audio_name"]}`;

          documents.push(doc);
        }
        if (documents.length) setDocumentInputs(documents);
        else setDocumentInputs([documentsInputFixture]);
      } else if (childInfo?.action === "edit") {
        documents = childInfo?.data?.documents;
        if (documents.length) setDocumentInputs(documents);
        else setDocumentInputs([documentsInputFixture]);
      }
    }
  }, [childInfo]); // eslint-disable-line

  useEffect(() => {
    if (childInfo?.data?.centerdetailId) {
      const center = centerNames.filter(
        (curr) => curr.id === childInfo.data.centerdetailId
      );
      if (center) setChildCenterName(center[0]?.name);
    }
  }, [centerNames, childInfo]);

  useEffect(() => {
    if (
      (childInfo?.action === "edit" || childInfo?.action === "view") &&
      activeTab === 0
    ) {
      updateOverviewForm(childInfo?.action);
    }
  }, [childInfo, activeTab]);

  useEffect(() => {
    if (info?.action && info?.data?.id) {
      getChildInfo(info?.data?.id, info.action);
    } else {
      if (!isActionAllowed("create", "child_registration")) {
        navigate("/child-registration");
      }
    }
  }, [info]); // eslint-disable-line

  useEffect(() => {
    if (
      activeTab === 0 &&
      (childInfo?.action === "edit" || childInfo?.action === "view")
    ) {
      updateOverviewForm(childInfo?.action);
    } else if (activeTab === 1) {
      setFixtureOptions("countryId", countries);
    } else if (activeTab === 2)
      setRegisterFormFixture(
        ChildRegisterFormsFixture({ tagNames: tags })[activeTab]
      );
    else
      setRegisterFormFixture(
        ChildRegisterFormsFixture({
          reference_type: registerFormInputs["reference_type"],
        })[activeTab]
      );
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const countryData = await getCountryList();
      await getStateCityOption("state", "101");
      const centerData = await getCenterNames();
      const tagData = await getTagNames();
      if (countryData) setCountries(countryData);
      if (centerData) setCenterNames(centerData);
      if (tagData) setTags(tagData);
      setLoading(false);
    }
    fetchData();
    let edit = isActionAllowed("edit", "child_registration");
    setIsEditAllowed(edit);
  }, []); // eslint-disable-line

  return (
    <main className="inner">
      <div className={` ${newRegistration ? "bg-white" : "d-flex flex-wrap"}`}>
        {!newRegistration ? (
          <div className="children-profile">
            <div className="bg-white h-100">
              <div
                className={`profile-edit text-center p-3 ${
                  childInfo?.action === "view" ? "" : "view-profile"
                }`}
              >
                <img
                  src={displayProfilePic ? displayProfilePic : userAvatar}
                  alt=""
                  onClick={() => handleProfileDownload(displayProfilePic)}
                />
                <span className="d-block pt-2">{childInfo?.data?.name}</span>
                <small className="d-block ">{childCenterName}</small>
                {isEditAllowed ? (
                  <span
                    className="btn btn-primary mt-2 text-white"
                    onClick={toggleAction}
                    role="button"
                  >
                    {childInfo?.action === "view"
                      ? "Edit Profile"
                      : "View Profile"}
                  </span>
                ) : null}
                {isEditAllowed && childStatus.reopen ? (
                  <span
                    className="btn btn-outline-danger mt-2 text-danger ml-2"
                    onClick={reopenChild}
                    disabled={loading}
                    role="button"
                    style={{ borderRadius: "2rem" }}
                  >
                    REOPEN
                  </span>
                ) : null}
              </div>
              <ul className="nav flex-column pb-lg-4">
                {childProfileNavFixture.map((obj, key) => (
                  <li className="nav-item" key={key}>
                    <span
                      role="button"
                      className={`nav-link ${
                        title === obj.text ? "active" : ""
                      }`}
                      onClick={() => setTitle(obj.text)}
                    >
                      <img {...obj.imageProps} alt="" />
                      {obj.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}

        <div className={`${newRegistration ? "" : "children-profile-main"}`}>
          <div className={`${newRegistration ? "" : "bg-white h-100"}`}>
            <div className="px-4 py-3 border-bottom header-page">
              <div className="d-flex align-items-md-center flex-column flex-md-row">
                <h5 className="page-title mb-2 mb-md-0 ">{title}</h5>
                <div className="button-group filter-button justify-content-md-end ml-md-auto">
                  {childInfo?.action === "edit" ? (
                    <>
                      {childStatus.action ? (
                        <ActionBtn setTitle={setTitle} />
                      ) : null}
                      {title === "Education" ||
                      title === "MHP" ||
                      title === "Medical" ? (
                        <span
                          className="btn btn-primary btn-add"
                          onClick={() => {
                            setOpenAddModal(true);
                          }}
                        >
                          Add
                        </span>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
              {title === "New Registration" && (
                <p className="m-0">
                  Please Fill In The Information For Child Registration.
                </p>
              )}
            </div>
            {title === "New Registration" || title === "Child’s Information" ? (
              <div className="tabsection">
                <div className="registration-tab mx-4 my-4">
                  <ul
                    className="nav nav-pills justify-content-between"
                    id="pills-tab"
                    role="tablist"
                  >
                    {profileTabFixture.map((tab, key) => (
                      <li className="nav-item" role="presentation" key={key}>
                        <button
                          className={`nav-link ${
                            progress >= key &&
                            (activeTab === 0 ? true : !loading) &&
                            activeTab === key
                              ? "active"
                              : ""
                          }`}
                          id="pills-overview-tab"
                          type="button"
                          role="tab"
                          onClick={() =>
                            progress >= key && !loading ? setActiveTab(key) : {}
                          }
                        >
                          {tab}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="tab-content p-4" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-overview"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="registration-form">
                      {activeTab !== 2 ? (
                        <Form
                          fixture={registerFormFixture}
                          inputs={registerFormInputs}
                          loading={loading}
                          onChange={handleInputChange}
                          onSubmit={handleSubmit}
                          nextBtnText={activeTab === 3 ? "Finish" : "Next"}
                          nextBtnClasses="btn-search"
                          // showBackBtn={childInfo?.action !== "view"}
                          showNextBtn={childInfo?.action !== "view"}
                          readOnlyFields={childInfo?.action === "view"}
                          showLoader={activeTab === 3}
                        >
                          {activeTab === 0 ? (
                            <div className="form-group">
                              <label>Profile Picture</label>
                              <div className="browsefile">
                                {registerFormInputs["image"] ? (
                                  <img
                                    className="fileicon"
                                    style={{ backgroundColor: "#fff" }}
                                    alt=""
                                    src={displayProfilePic}
                                  />
                                ) : (
                                  <div className="fileicon"></div>
                                )}

                                <div className="textalgin">
                                  {registerFormInputs["image"] ? (
                                    <span className="brtx">
                                      {typeof registerFormInputs["image"] ===
                                      "object"
                                        ? registerFormInputs["image"].name
                                        : registerFormInputs["image"]}
                                    </span>
                                  ) : (
                                    <>
                                      <span className="brtx">
                                        DROP YOU IMAGE HERE OR{" "}
                                        <span>BROWSE</span>
                                      </span>
                                      <p>
                                        SUPPORT: JPG, JPEG, PNG, (UPTO 4 MB)
                                      </p>
                                    </>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  className="fileChoose"
                                  id="formFileLg"
                                  disabled={childInfo?.action === "view"}
                                  accept=".jpg,.jpeg,.png"
                                  name="image"
                                  src={registerFormInputs["image"]}
                                  onChange={(e) =>
                                    onChangeInputData(
                                      e,
                                      registerFormInputs,
                                      setRegisterFormInputs
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Form>
                      ) : (
                        documentInputs.map((docInput, key) => (
                          <Fragment key={key}>
                            <Form
                              fixture={registerFormFixture}
                              inputs={docInput}
                              loading={loading}
                              onChange={(e) => handleDocInputChange(e, key)}
                              onSubmit={handleSubmit}
                              nextBtnText="Next"
                              nextBtnClasses="btn-search"
                              showNextBtn={
                                key === documentInputs.length - 1 &&
                                childInfo?.action !== "view"
                                  ? true
                                  : false
                              }
                              showBackBtn={
                                key === documentInputs.length - 1 &&
                                childInfo?.action !== "view"
                                  ? true
                                  : false
                              }
                              backBtnText="Add More"
                              backBtnClasses="btn-add"
                              handleBack={addMoreDocuments}
                              showUpdateBtn={
                                childInfo?.action === "edit" && docInput["id"]
                              }
                              showDeleteBtn={
                                (childInfo?.action === "edit" &&
                                  docInput["id"] &&
                                  isActionAllowed("delete", "child_document") &&
                                  key !== 0) ||
                                (!docInput["id"] && key !== 0)
                              }
                              handleUpdate={handleDocumentUpdate}
                              handleDelete={(d) =>
                                handleDeleteDocuments(d, key)
                              }
                              readOnlyFields={childInfo?.action === "view"}
                            ></Form>
                          </Fragment>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : title === "Follow-Ups" ? (
              <FollowUps
                title={title}
                childInfo={childInfo}
                getChildInfo={getChildInfo}
                documentPath={documentPath}
                audioPath={audioPath}
                isViewMode={childInfo?.action === "view"}
                isEditAllowed={isEditAllowed}
              />
            ) : (
              <MoreDetails
                title={title}
                childInfo={childInfo}
                centerNames={centerNames}
                tagNames={tags}
                isViewMode={childInfo?.action === "view"}
                isEditAllowed={isEditAllowed}
                getChildInfo={getChildInfo}
                documentPath={documentPath}
                audioPath={audioPath}
                openModal={openAddModal}
                setOpenModal={setOpenAddModal}
                setTitle={setTitle}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ChildProfile;
