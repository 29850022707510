export const endpoints = {
  login: `${process.env.REACT_APP_API_URL}/login`,
  getCountries: `${process.env.REACT_APP_API_URL}/country`,
  getStates: (countryId) =>
    `${process.env.REACT_APP_API_URL}/state/${countryId}`,
  getCities: (stateId) => `${process.env.REACT_APP_API_URL}/city/${stateId}`,
  getDistricts: (stateId) =>
    `${process.env.REACT_APP_API_URL}/districts/${stateId}`,

  //Center Management Endpoints
  getCenterNames: `${process.env.REACT_APP_API_URL}/centers_list`,
  centers_points: `${process.env.REACT_APP_API_URL}/centers_points`,
  addCenter: `${process.env.REACT_APP_API_URL}/center_add`,
  getCenters: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/centers?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/centers`;
  },
  updateCenter: `${process.env.REACT_APP_API_URL}/center_update`,
  deleteCenter: (id) => `${process.env.REACT_APP_API_URL}/center_delete/${id}`,
  updateCenterStatus: (id, status) =>
    `${process.env.REACT_APP_API_URL}/center_status/${id}/${status}`,
  getCenterData: `${process.env.REACT_APP_API_URL}/centers_details`,

  // Tag Management Endpoints
  getAllTags: `${process.env.REACT_APP_API_URL}/tags_all`,
  getTags: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/tags?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/tags`;
  },
  addTag: `${process.env.REACT_APP_API_URL}/tag_add`,
  updateTag: `${process.env.REACT_APP_API_URL}/tag_update`,
  deleteTag: (id) => `${process.env.REACT_APP_API_URL}/tag_delete/${id}`,

  // Role Management Endpoints
  getRoles: `${process.env.REACT_APP_API_URL}/roles`,
  addRole: `${process.env.REACT_APP_API_URL}/role_add`,
  updateRole: `${process.env.REACT_APP_API_URL}/role_update`,
  deleteRole: (id) => `${process.env.REACT_APP_API_URL}/role_delete/${id}`,
  updateRolePermission: `${process.env.REACT_APP_API_URL}/role_permission`,

  // User Management Endpoints
  getUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/users?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/users`;
  },
  addUser: `${process.env.REACT_APP_API_URL}/register`,
  updateUser: `${process.env.REACT_APP_API_URL}/user_update`,
  deleteUser: (id) => `${process.env.REACT_APP_API_URL}/user_delete/${id}`,

  //Child Registration Management Endpoint
  getChildRegistrations: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/child-registrations?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/child-registrations`;
  },
  quickAdd: `${process.env.REACT_APP_API_URL}/child_add_2`,
  addChild: `${process.env.REACT_APP_API_URL}/child_add`,
  deleteChild: (id) => `${process.env.REACT_APP_API_URL}/child_delete/${id}`,
  viewChild: (id) => `${process.env.REACT_APP_API_URL}/child_view/${id}`,
  getEditChildData: (id) => `${process.env.REACT_APP_API_URL}/child_edit/${id}`,
  updateChild: `${process.env.REACT_APP_API_URL}/child_update`,
  updateDocuments: `${process.env.REACT_APP_API_URL}/child_document_update`,
  deleteDocuemnts: (id) =>
    `${process.env.REACT_APP_API_URL}/child_documents_delete/${id}`,
  getActivityLogs: (id) =>
    `${process.env.REACT_APP_API_URL}/activitylogs/${id}`,
  deleteActivityLogs: (id) =>
    `${process.env.REACT_APP_API_URL}/activitylogs_delete/${id}`,
  getDocumentList: (id) =>
    `${process.env.REACT_APP_API_URL}/documents_lists/${id}`,
  allStatusListing: (listingFor, id) =>
    `${process.env.REACT_APP_API_URL}/listing/${listingFor}/${id}`,
  saveMedicalConsent: `${process.env.REACT_APP_API_URL}/medical_consent`,
  saveMHP: `${process.env.REACT_APP_API_URL}/mhp`,
  saveEducation2: `${process.env.REACT_APP_API_URL}/education`,
  saveRestoration_follow_up: `${process.env.REACT_APP_API_URL}/restoration_follow_up`,
  saveRestoration: `${process.env.REACT_APP_API_URL}/restoration`,
  saveTransfer: `${process.env.REACT_APP_API_URL}/save_transfer`,
  saveEscortExit: `${process.env.REACT_APP_API_URL}/save_excort_exit`,
  saveSPS: `${process.env.REACT_APP_API_URL}/social_protection_scheme`,
  deleteSPS: (id) => `${process.env.REACT_APP_API_URL}/listing_delete/${id}`,
  childStatus: (id) => `${process.env.REACT_APP_API_URL}/child_status/${id}`,
  reopen: (id) => `${process.env.REACT_APP_API_URL}/reopen/${id}`,
  follow_up: `${process.env.REACT_APP_API_URL}/follow_up`,

  //CWC Management Endpoint
  getProduceList: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/produce?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/produce`;
  },
  deleteProduceToCWC: (id) =>
    `${process.env.REACT_APP_API_URL}/produce_to_cwc_delete/${id}`,
  editProduceToCWC: (id) =>
    `${process.env.REACT_APP_API_URL}/produce_to_cwc/${id}`,
  getReferCwcList: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/refer?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/refer`;
  },
  deleteReferCWC: (id) =>
    `${process.env.REACT_APP_API_URL}/refer_to_cwc_delete/${id}`,
  editReferCWC: (id) => `${process.env.REACT_APP_API_URL}/refer_to_cwc/${id}`,
  addProduceToCWC: `${process.env.REACT_APP_API_URL}/produce_to_cwc`,
  addReferToCWC: `${process.env.REACT_APP_API_URL}/refer_to_cwc`,

  //Education Endpoints
  getEducationRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/regusers_education?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/regusers_education`;
  },
  getEducationNonRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/non_regusers_education?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/non_regusers_education`;
  },
  getEducationOptions: `${process.env.REACT_APP_API_URL}/add_education`,
  saveEducation: `${process.env.REACT_APP_API_URL}/save_education`,
  deleteEducation: (id) =>
    `${process.env.REACT_APP_API_URL}/education_delete/${id}`,
  updateEducation: `${process.env.REACT_APP_API_URL}/update_education`,

  //Medical Endpoints
  getMedicalRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/regusers_medical?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/regusers_medical`;
  },

  getMedicalNonRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/non_regusers_medical?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/non_regusers_medical`;
  },
  getMedicalOptions: `${process.env.REACT_APP_API_URL}/add_medical`,
  saveMedical: `${process.env.REACT_APP_API_URL}/save_medical`,
  deleteMedical: (id) =>
    `${process.env.REACT_APP_API_URL}/medical_delete/${id}`,
  updateMedical: `${process.env.REACT_APP_API_URL}/update_medical`,

  //MHP Endpoints
  getMhpRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/regusers_mhp?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/regusers_mhp`;
  },
  getMhpNonRegUsers: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/non_regusers_mhp?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/non_regusers_mhp`;
  },
  getMhpOptions: `${process.env.REACT_APP_API_URL}/add_mhp`,
  saveMhp: `${process.env.REACT_APP_API_URL}/save_mhp`,
  deleteMhp: (id) => `${process.env.REACT_APP_API_URL}/mhp_delete/${id}`,
  updateMhp: `${process.env.REACT_APP_API_URL}/update_mhp`,

  // Restoration and Exit Management
  getRestore_Exit_Management: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/management?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/management`;
  },
  getChildShortStayRegister: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/child_short_stay?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/child_short_stay`;
  },

  // Dashboard endpoint
  getDashboardDetails: `${process.env.REACT_APP_API_URL}/dashboard`,

  //Report Endpoints
  groupReport: `${process.env.REACT_APP_API_URL}/groupreports`,
  groupReport2: `${process.env.REACT_APP_API_URL}/groupreports_latest`,
  reports: `${process.env.REACT_APP_API_URL}/reports`,

  // Bulk Update
  bulkUpdate: `${process.env.REACT_APP_API_URL}/bulk_update`,

  //Single report
  singleReport: `${process.env.REACT_APP_API_URL}/single_report`,

  //Group Single report
  groupSingleReport: `${process.env.REACT_APP_API_URL}/new_single_report`,

  // Restoration delete
  listingDelete: (id) =>
    `${process.env.REACT_APP_API_URL}/listing_delete/${id}`,

  // Center history
  centersHistory: (id) =>
    `${process.env.REACT_APP_API_URL}/center_history/${id}`,
  centersHistoryDelete: (id) =>
    `${process.env.REACT_APP_API_URL}/center_history_delete/${id}`,
  changeCenter: `${process.env.REACT_APP_API_URL}/center_change`,

  // Meal Management
  getMealList: `${process.env.REACT_APP_API_URL}/meal_list`,

  // Logout
  logout: `${process.env.REACT_APP_API_URL}/logout`,

  //other Our Services
  educationList: `${process.env.REACT_APP_API_URL}/education_lists`,
  medicalList: `${process.env.REACT_APP_API_URL}/medical_lists`,
  mhpList: `${process.env.REACT_APP_API_URL}/mhp_lists`,

  //Notification Endpoints
  notifyListCount: (centerId) =>
    `${process.env.REACT_APP_API_URL}/notify_list_count/${centerId}`,
  notifyList: (centerId) =>
    `${process.env.REACT_APP_API_URL}/notify_list/${centerId}`,
  notifyUpdate: (id) => `${process.env.REACT_APP_API_URL}/notify_update/${id}`,

  //Download Image
  downloadImages: `${process.env.REACT_APP_API_URL}/download_images`,

  // SPS Management Endpoints
  getAllSPS: `${process.env.REACT_APP_API_URL}/sps_all`,
  getSps: (page_num) => {
    return page_num
      ? `${process.env.REACT_APP_API_URL}/sps?page=${page_num}`
      : `${process.env.REACT_APP_API_URL}/sps`;
  },
  addSps: `${process.env.REACT_APP_API_URL}/sps_add`,
  updateSps: `${process.env.REACT_APP_API_URL}/sps_update`,
};
