import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import {
  UserTableHeaderFixture,
  filterInputFixture,
} from "./utilities/fixtures";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import FilterRegistrations from "./FilterRegistrations";
import { useNavigate } from "react-router-dom";
import { getCenterNames, isActionAllowed } from "../../utilities/helpers";
import { toast } from "react-toastify";
import BulkUpload from "./BulkUpload";
import QuickAdd from "./QuickAdd";

const ChildRegistration = () => {
  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(
    filterInputFixture(userDetails)
  );
  const [registrationData, setRegistrationData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [imagePath, setImagePath] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  const [selectedChilds, setSelectedChilds] = useState({});
  const [selectedChildIds, setSelectedChildIds] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState(false);
  const [quickAdd, setQuickAdd] = useState(false);
  const [filterApplied, applyFilter] = useState(false);

  const navigate = useNavigate();

  const handlePageSelect = (selected) => {
    if (filterApplied) getChildRegistrations(selected, filterDetails);
    else getChildRegistrations(selected);
  };

  const getChildRegistrations = async (
    page_num,
    filterData = false,
    successCb = false
  ) => {
    if (loading) return;
    setLoading(true);
    const { success, res, data } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getChildRegistrations"](page_num)
        : endpoints["getChildRegistrations"](),
      data: filterData ? filterDetails : {},
    });
    setLoading(false);
    if (success && data) {
      setRegistrationData(data);
      if (filterData) applyFilter(true);
      if (res?.pagination) setPaginationData(res.pagination);
      if (res?.image_path) setImagePath(res?.image_path);
      if (successCb) successCb();
    }
  };

  const deleteChild = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteChild"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("Child deleted successfully");
      setRegistrationData((prev) => prev.filter((item) => item.id !== id));
    } else {
      toast.error("Something went wrong!");
    }
  };

  const getChildInfo = async (id, action) => {
    // if (loading || !id) return;
    // setLoading(true);
    // const { success, img_profile, image_path, img_documents, data } =
    //   await getDataWithAuth({
    //     api_url:
    //       action === "edit"
    //         ? endpoints["getEditChildData"](id)
    //         : endpoints["viewChild"](id),
    //   });
    // setLoading(false);
    // if (success) {
    //   let childInfo = {
    //     img_profile,
    //     image_path,
    //     img_documents,
    //     data,
    //     action,
    //   };
    //   navigate("profile", { state: childInfo });
    // } else {
    //   toast.error("Something went wrong!");
    // }
    navigate("profile", { state: { data: { id }, action } });
  };

  const handleSelectChild = (e, rowData) => {
    setSelectedChilds((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
    if (e.target.checked) {
      let centerid = Array.isArray(rowData["center_data"])
        ? rowData?.center_data[rowData?.center_data.length - 1]?.id || ""
        : rowData["center_data"]?.id || "";
      setSelectedChildIds((prev) => [
        ...prev,
        { childregId: rowData["id"], centerId: centerid },
      ]);
    } else {
      setSelectedChildIds((prev) =>
        prev.filter((obj) => obj.childregId !== rowData["id"])
      );
    }
  };

  const resetSelection = () => {
    setSelectedChildIds([]);
    setSelectedChilds({});
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getCenterNames();
      if (data) setCenterNames(data);
      await getChildRegistrations();
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Child Registration List"
          tableHeadersFixture={UserTableHeaderFixture(
            isActionAllowed("edit", "child_registration"),
            selectedChildIds?.length
          )}
          data={registrationData}
          loading={loading}
          startSerialNum={paginationData?.from}
          onDelete={(rowData) => deleteChild(rowData["id"])}
          onEdit={(rowData) => getChildInfo(rowData["id"], "edit")}
          onView={(rowData) => getChildInfo(rowData["id"], "view")}
          onSelectRow={(e, rowData) => handleSelectChild(e, rowData)}
          selectedRows={selectedChilds}
          showPagination={true}
          onPageSelect={(selected) => handlePageSelect(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
          imagePath={imagePath}
        >
          <div className="button-group filter-button justify-content-md-end flex-column flex-md-row">
            <button
              type="button"
              className="btn btn-primary btn-primary-border"
              onClick={() => {
                setFilterModal(true);
              }}
            >
              Filters
            </button>
            {isActionAllowed("create", "child_registration") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => navigate("profile")}
              >
                Add Child
              </span>
            ) : null}
            {isActionAllowed("create", "child_registration") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => setQuickAdd(true)}
              >
                Quick Add
              </span>
            ) : null}
            {selectedChildIds.length > 1 &&
            isActionAllowed("edit", "child_registration") ? (
              <span
                className="btn btn-primary"
                onClick={() => setBulkUpdate(true)}
              >
                Bulk Update
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      <FilterRegistrations
        openModal={filterModal}
        setOpenModal={setFilterModal}
        filterDetails={filterDetails}
        setFilterDetails={setFilterDetails}
        centerNames={centerNames}
        loading={loading}
        getAllRegistrations={getChildRegistrations}
        userDetails={userDetails}
        applyFilter={applyFilter}
      />

      <BulkUpload
        loading={loading}
        openModal={bulkUpdate}
        setOpenModal={setBulkUpdate}
        centerNames={centerNames}
        selectedChildIds={selectedChildIds}
        resetSelection={resetSelection}
        setLoading={setLoading}
      />

      <QuickAdd
        loading={loading}
        openModal={quickAdd}
        setOpenModal={setQuickAdd}
        centerNames={centerNames}
        setLoading={setLoading}
        getAllRegistrations={getChildRegistrations}
      />
    </>
  );
};

export default ChildRegistration;
