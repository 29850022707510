import React from "react";
import { capitalizeWords } from "../../utilities/helpers";
import { permissionLabelclass } from "./utilities/fixture";

const RolePermission = ({
  text = "",
  keyProp = "",
  permissionFor = [],
  permissionDetails = {},
  setPermissionDetails = () => {},
}) => {
  const handleOnChange = ({ target }) => {
    const { name, checked } = target;
    setPermissionDetails((prev) => ({
      ...prev,
      [keyProp]: { ...prev[keyProp], [name]: checked },
    }));
  };

  return (
    <form className="mb-4">
      <div className="form-label">{text}</div>
      <div className="d-flex justify-content-between">
        {permissionFor?.map((item, ind) => (
          <label
            htmlFor={`${keyProp}checkbox${ind + 1}`}
            className={`option ${permissionLabelclass[item]}`}
            key={ind}
          >
            {capitalizeWords(item)}
            <input
              type="checkbox"
              id={`${keyProp}checkbox${ind + 1}`}
              name={item}
              checked={!!permissionDetails[keyProp]?.[item]}
              onChange={handleOnChange}
            />
            <span className={`checkbox checkbox${ind + 1}`}></span>
          </label>
        ))}
      </div>
    </form>
  );
};

export default RolePermission;
